// http://localforage.docschina.org/#
import localforage from 'localforage';

const storageName = 'ori-protocol-v4';

const storage: LocalForage = localforage.createInstance({
    name: storageName,
});

// storage.getItem<T>(key: string, callback?: (err: any, value: T | null) => void): Promise<T | null>;

// storage.setItem<T>(key: string, value: T, callback?: (err: any, value: T) => void): Promise<T>;

// storage.removeItem(key: string, callback?: (err: any) => void): Promise<void>;

// storage.clear(callback?: (err: any) => void): Promise<void>;

// storage.length(callback?: (err: any, numberOfKeys: number) => void): Promise<number>;

// storage.key(keyIndex: number, callback?: (err: any, key: string) => void): Promise<string>;

export default storage;
