import request from '@/utils/request';
import type { ProfileParams, Profile } from '@/types/profile';
export const getProfile = (params: ProfileParams) => {
    return request<Profile[]>({
        url: '/nfts/profile',
        method: 'get',
        params,
    });
};

export const getByOwner = (params: ProfileParams) => {
    return request<any[]>({
        url: '/collections/by_owner',
        method: 'get',
        params,
    });
};
