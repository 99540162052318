import walletIcon from '@/assets/svgs/wallet_icon.svg';
import { useUserInfo } from '@/context/UserInfoContext';
import { isMobile, maxZIndex, signAccount, switchChain } from '@/utils/index';
import { useRouter } from 'next/router';
import { memo, useEffect, useState } from 'react';

const ApprovePopup = () => {
    const router = useRouter();
    const { userInfo } = useUserInfo();
    const [containerClassList, setContainerClassList] = useState(['ori-particle-approve-popup']);
    const [zIndex, setZIndex] = useState<number>(100);

    useEffect(() => {
        if (userInfo?.isLogin && userInfo?.events) {
            userInfo.events.on('event:switchChain:approve', () => {
                setZIndex(maxZIndex() + 1);
                setContainerClassList(['ori-particle-approve-popup', 'ori-particle-approve-popup-show']);
            });
            userInfo.events.on('event:switchChain:success', () => {
                // pass;
            });
        }
    }, [userInfo?.isLogin, userInfo?.events]);

    return (
        <div
            className={containerClassList.join(' ')}
            style={{
                zIndex,
            }}
        >
            <div className="ori-particle-pam-left">
                <div className="ori-particle-pam-wrap">
                    <div className="ori-particle-pam-img">
                        <img src={walletIcon} alt="" />
                    </div>
                    <div className="ori-particle-pam-content">
                        <div className="ori-particle-pam-title">Approve Pop-up</div>
                        <div className="ori-particle-pam-text">
                            Click Continue to complete the signature or transaction
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="ori-particle-pam-right ori-parm-continue-btn"
                onClick={() => {
                    userInfo.events.emit('event:switchChain:execute');
                    setContainerClassList(['ori-particle-approve-popup']);
                }}
            >
                Continue
            </div>
        </div>
    );
};

export default memo(ApprovePopup);
