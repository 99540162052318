import React from 'react';
import { Button, Col, Input, Row } from 'antd';

import type { InputProps } from 'antd';

import styles from './style.module.less';

const Index = ({ className = '', ...inputProps }: InputProps) => {
    return <Input className={`${className} ${styles.input} `} {...inputProps} />;
};
export default Index;
