import request from '@/utils/request';
import { v4 as uuidv4 } from 'uuid';
import dayjs from 'dayjs';
import type { UploadRequestOption, RcFile } from 'rc-upload/lib/interface';

export const uploadFile = async (options: UploadRequestOption<any>) => {
    const file = options.file as RcFile;
    // const fileName = `images/${dayjs().format('YYYYMMDD')}/${uuidv4()}.${file.name?.split?.('.')?.pop?.()}`;
    const formData = new FormData();
    formData.append('file', options.file);
    return request({
        url: '/upload-png',
        method: 'post',
        data: formData,
        params: {
            // fileName,
        },
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};
