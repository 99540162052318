import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

export function sentryInit() {
    Sentry.init({
        dsn: 'https://505daf029733464fb54dc7164c9dd423@o1241866.ingest.sentry.io/6761689',
        integrations: [new BrowserTracing()],
        tracesSampleRate: 1.0,
    });
    return Sentry;
}
