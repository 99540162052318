import Web3 from 'web3';
import BN from 'bn.js';
import type { Provider } from '@particle-network/connect';
import { switchChain } from '@/utils/index';

const FACTORY_ABI: any[] = [
    {
        inputs: [
            {
                internalType: 'address',
                name: 'originToken',
                type: 'address',
            },
        ],
        name: 'licenseToken',
        outputs: [
            {
                internalType: 'address',
                name: '',
                type: 'address',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: 'originToken',
                type: 'address',
            },
        ],
        name: 'derivativeToken',
        outputs: [
            {
                internalType: 'address',
                name: '',
                type: 'address',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
];
const TOKEN_OPERATOR_ABI: any[] = [
    {
        inputs: [
            {
                internalType: 'bytes[]',
                name: 'datas',
                type: 'bytes[]',
            },
        ],
        name: 'multicall',
        outputs: [
            {
                internalType: 'bytes[]',
                name: 'results',
                type: 'bytes[]',
            },
        ],
        stateMutability: 'payable',
        type: 'function',
    },
    {
        anonymous: false,
        inputs: [
            {
                indexed: true,
                internalType: 'address',
                name: 'to',
                type: 'address',
            },
            {
                indexed: true,
                internalType: 'address',
                name: 'token',
                type: 'address',
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'tokenId',
                type: 'uint256',
            },
            {
                indexed: false,
                internalType: 'uint256',
                name: 'amount',
                type: 'uint256',
            },
        ],
        name: 'Mint',
        type: 'event',
    },
    {
        inputs: [
            {
                internalType: 'contract ITokenActionable',
                name: 'dToken',
                type: 'address',
            },
            {
                internalType: 'uint256',
                name: 'amount',
                type: 'uint256',
            },
            {
                internalType: 'bytes',
                name: 'meta',
                type: 'bytes',
            },
        ],
        name: 'createDerivative',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'string',
                name: 'dName',
                type: 'string',
            },
            {
                internalType: 'string',
                name: 'dSymbol',
                type: 'string',
            },
            {
                internalType: 'uint256',
                name: 'amount',
                type: 'uint256',
            },
            {
                internalType: 'bytes',
                name: 'meta',
                type: 'bytes',
            },
        ],
        name: 'createDerivativeToNew',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: 'originToken',
                type: 'address',
            },
            {
                internalType: 'uint256',
                name: 'amount',
                type: 'uint256',
            },
            {
                internalType: 'bytes',
                name: 'meta',
                type: 'bytes',
            },
        ],
        name: 'createLicense',
        outputs: [],
        stateMutability: 'payable',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'uint256',
                name: 'amount',
                type: 'uint256',
            },
            {
                internalType: 'uint64',
                name: 'expiredAt',
                type: 'uint64',
            },
        ],
        name: 'calculateMintFee',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'contract ITokenActionable',
                name: 'token',
                type: 'address',
            },
            {
                internalType: 'uint256',
                name: 'id',
                type: 'uint256',
            },
            {
                internalType: 'uint256',
                name: 'amount',
                type: 'uint256',
            },
        ],
        name: 'mint',
        outputs: [],
        stateMutability: 'payable',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'contract ITokenActionable',
                name: 'token',
                type: 'address',
            },
            {
                internalType: 'uint256',
                name: 'id',
                type: 'uint256',
            },
            {
                internalType: 'uint256',
                name: 'amount',
                type: 'uint256',
            },
        ],
        name: 'burn',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
    {
        inputs: [
            {
                components: [
                    {
                        internalType: 'address',
                        name: 'token',
                        type: 'address',
                    },
                    {
                        internalType: 'address',
                        name: 'from',
                        type: 'address',
                    },
                    {
                        internalType: 'address',
                        name: 'to',
                        type: 'address',
                    },
                    {
                        internalType: 'uint256',
                        name: 'validAfter',
                        type: 'uint256',
                    },
                    {
                        internalType: 'uint256',
                        name: 'validBefore',
                        type: 'uint256',
                    },
                    {
                        internalType: 'bytes32',
                        name: 'salt',
                        type: 'bytes32',
                    },
                    {
                        internalType: 'bytes',
                        name: 'signature',
                        type: 'bytes',
                    },
                ],
                internalType: 'struct ApproveAuthorization[]',
                name: 'approves',
                type: 'tuple[]',
            },
        ],
        name: 'receiveApproveAuthorization',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
    },
];
const TOKEN_ABI: any[] = [
    {
        inputs: [],
        name: 'originToken',
        outputs: [
            {
                internalType: 'address',
                name: '',
                type: 'address',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
];
const LICENSE_TOKEN_ABI: any[] = [
    ...TOKEN_ABI,
    {
        inputs: [
            {
                internalType: 'uint256[]',
                name: 'ids',
                type: 'uint256[]',
            },
        ],
        name: 'metas',
        outputs: [
            {
                components: [
                    {
                        internalType: 'uint256',
                        name: 'originTokenId',
                        type: 'uint256',
                    },
                    {
                        internalType: 'uint16',
                        name: 'earnPoint',
                        type: 'uint16',
                    },
                    {
                        internalType: 'uint64',
                        name: 'expiredAt',
                        type: 'uint64',
                    },
                ],
                internalType: 'struct LicenseMeta[]',
                name: 'licenseMetas',
                type: 'tuple[]',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: 'account',
                type: 'address',
            },
            {
                internalType: 'uint256',
                name: 'id',
                type: 'uint256',
            },
        ],
        name: 'balanceOf',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
];
const DERIVATIVE_TOKEN_ABI: any[] = [
    ...TOKEN_ABI,
    {
        inputs: [
            {
                internalType: 'uint256[]',
                name: 'ids',
                type: 'uint256[]',
            },
        ],
        name: 'metas',
        outputs: [
            {
                components: [
                    {
                        components: [
                            {
                                internalType: 'address',
                                name: 'token',
                                type: 'address',
                            },
                            {
                                internalType: 'uint256',
                                name: 'id',
                                type: 'uint256',
                            },
                        ],
                        internalType: 'struct NFT[]',
                        name: 'licenses',
                        type: 'tuple[]',
                    },
                    {
                        internalType: 'uint256',
                        name: 'supplyLimit',
                        type: 'uint256',
                    },
                    {
                        internalType: 'uint256',
                        name: 'totalSupply',
                        type: 'uint256',
                    },
                ],
                internalType: 'struct DerivativeMeta[]',
                name: 'derivativeMetas',
                type: 'tuple[]',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
    {
        inputs: [
            {
                internalType: 'address',
                name: 'owner',
                type: 'address',
            },
        ],
        name: 'balanceOf',
        outputs: [
            {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
            },
        ],
        stateMutability: 'view',
        type: 'function',
    },
];

export type DerivativeTokenMeta = {
    name: string;
    description: string;
    image: string;
    decimals?: number;
    properties?: object;
    external_url?: string;
    nftType?: string;
    licenseToken?: string;
    licenseTokenId?: any;
    attributes?: { trait_type: string; value: string | number; max_value?: string | number; display_type?: string }[];
};
/**
 *
 * createLicense    创建 license 不存在则部署
 *
 * getApproveSignatures（获取授权） => createDerivative（创建已有 DToken） | createDerivativeToNew（部署新 DToken 并创建） => decodeCreateDerivativeToken（解析 token id）
 *
 * licenseToken     获取 OToken 对应到 LToken
 * derivativeToken  获取 OToken 对应到 DToken
 *
 * getLicenseMetas      获取 LTokens meta 信息
 * getDerivativeMetas   获取 DTokens meta 信息
 *
 * getLicenseBalanceOf      LToken balance
 * getDerivativeBalanceOf   DToken balance
 *
 * mintToken    铸造更多 Token
 * burnToken    销毁 Token
 *
 */
export class Ori {
    private readonly particleProvider: any;
    private readonly userAddress;
    private readonly web3: Web3;

    private readonly chainId;
    public readonly factoryAddress;
    public readonly tokenOperatorAddress;
    private readonly factory;
    private readonly tokenOperator;

    public constructor(particleProvider?: any) {
        // @ts-ignore
        this.particleProvider = particleProvider || window.__particleProvider;
        // @ts-ignore
        this.userAddress = window?.__userInfo?.address;
        this.chainId = Number(process.env.NEXT_PUBLIC_CHAIN_ID);
        // this.switchChain();

        this.web3 = new Web3(this.particleProvider);

        this.factoryAddress = process.env.NEXT_PUBLIC_ORI_FACTORY_ADDRESS; // 0x9B09cB6f27b6A646992bBE999b43426f14DCa366
        this.tokenOperatorAddress = process.env.NEXT_PUBLIC_ORI_TOKEN_OPERATOR_ADDRESS; // 0xC039ea6c3e01aA57691D8DECEDe7919e9B8761d2

        this.factory = new this.web3.eth.Contract(FACTORY_ABI, this.factoryAddress);
        this.tokenOperator = new this.web3.eth.Contract(TOKEN_OPERATOR_ABI, this.tokenOperatorAddress);
    }

    private async switchChain() {
        return switchChain(this.particleProvider);
    }

    public async licenseToken(originToken: string): Promise<string> {
        await this.switchChain();

        return this.factory.methods.licenseToken(originToken).call();
    }

    public async derivativeToken(originToken: string): Promise<string> {
        await this.switchChain();

        return this.factory.methods.derivativeToken(originToken).call();
    }

    public async isDeployed(originToken: string): Promise<boolean> {
        return (await this.licenseToken(originToken)) !== '0x0000000000000000000000000000000000000000';
    }

    public async getLicenseMetas(tokenAddress: string, tokenIds: number[] | string[]): Promise<any> {
        const token = new this.web3.eth.Contract(LICENSE_TOKEN_ABI, tokenAddress);
        await this.switchChain();

        return token.methods.metas(tokenIds).call();
    }

    public async getDerivativeMetas(tokenAddress: string, tokenIds: number[] | string[]): Promise<any> {
        const token = new this.web3.eth.Contract(DERIVATIVE_TOKEN_ABI, tokenAddress);
        await this.switchChain();

        return token.methods.metas(tokenIds).call();
    }

    public async getLicenseBalanceOf(tokenAddress: string, account: string, tokenId: number | string) {
        const token = new this.web3.eth.Contract(LICENSE_TOKEN_ABI, tokenAddress);
        await this.switchChain();

        return token.methods.balanceOf(account, tokenId).call();
    }

    public async getDerivativeBalanceOf(tokenAddress: string, account: string) {
        const token = new this.web3.eth.Contract(DERIVATIVE_TOKEN_ABI, tokenAddress);
        await this.switchChain();

        return token.methods.balanceOf(account).call();
    }

    public async getOriginToken(tokenAddress: string) {
        const token = new this.web3.eth.Contract(TOKEN_ABI, tokenAddress);
        await this.switchChain();

        return token.methods.originToken().call();
    }

    public async mintToken(
        tokenAddress: string,
        tokenId: number | string,
        tokenAmount: number,
        value = '0x0'
    ): Promise<any> {
        console.log('mintTokenParams', tokenAddress, tokenId, tokenAmount);

        await this.switchChain();

        const txnHash = await this.particleProvider.request({
            method: 'eth_sendTransaction',
            params: [
                {
                    chainId: this.chainId,
                    from: this.userAddress,
                    to: this.tokenOperatorAddress,
                    nonce: '0x0',
                    value: value,
                    data: await this.tokenOperator.methods.mint(tokenAddress, tokenId, tokenAmount).encodeABI(),
                },
            ],
        });

        await this.getTransactionReceipt(txnHash);

        return txnHash;
    }

    public async burnToken(tokenAddress: string, tokenId: number | string, tokenAmount: number): Promise<any> {
        await this.switchChain();

        const txnHash = await this.particleProvider.request({
            method: 'eth_sendTransaction',
            params: [
                {
                    chainId: this.chainId,
                    from: this.userAddress,
                    value: '0x0',
                    nonce: '0x0',
                    to: this.tokenOperatorAddress,
                    data: await this.tokenOperator.methods.burn(tokenAddress, tokenId, tokenAmount).encodeABI(),
                },
            ],
        });

        await this.getTransactionReceipt(txnHash);
        return { txnHash };
    }

    public async calculateMintFee(amount: number, expiredTime: number): Promise<any> {
        const block = await this.getLatestBlock();
        const fee = await this.tokenOperator.methods.calculateMintFee(amount, block.timestamp + expiredTime).call();
        console.log(
            '🚀 ~ file: ori2.ts ~ line 609 ~ Ori ~ calculateMintFee ~ this.web3.utils.toHex(fee)',
            this.web3.utils.toHex(fee)
        );
        return this.web3.utils.toHex(fee);
    }

    public async createLicense(
        originToken: string,
        originTokenId: number | string,
        licenseAmount: number,
        earnPoint: number | string,
        expiredTime: number
    ): Promise<any> {
        console.log('createLicenseParams', {
            originToken,
            originTokenId,
            licenseAmount,
            earnPoint,
            expiredTime,
        });
        await this.switchChain();
        const block = await this.getLatestBlock();
        const fee = await this.calculateMintFee(licenseAmount, expiredTime);
        // const fee = await this.tokenOperator.method,s
        //     .calculateMintFee(licenseAmount, block.timestamp + expiredTime)
        //     .call();
        const txnHash = await this.particleProvider.request({
            method: 'eth_sendTransaction',
            params: [
                {
                    chainId: this.chainId,
                    from: this.userAddress,
                    value: fee,
                    nonce: '0x0',
                    to: this.tokenOperatorAddress,
                    data: await this.tokenOperator.methods
                        .createLicense(
                            originToken,
                            licenseAmount,
                            this.web3.eth.abi.encodeParameters(
                                ['uint256', 'uint16', 'uint64'],
                                [originTokenId, earnPoint, block.timestamp + expiredTime]
                            )
                        )
                        .encodeABI(),
                },
            ],
        });

        const receipt = await this.getTransactionReceipt(txnHash);

        return this.decodeMintEvent(receipt?.logs);
    }

    public async getApproveSignatures(licenseTokenAddress: string[]): Promise<any> {
        await this.switchChain();

        const approves = [];
        const block = await this.getLatestBlock();
        const validAfter = block.timestamp - 60;
        const validBefore = block.timestamp + 1800; // 30 minutes

        for await (const address of licenseTokenAddress) {
            const salt = this.web3.utils.keccak256((+new Date()).toString());
            const signature = await this.particleProvider.request({
                method: 'eth_signTypedData_v4',
                params: [
                    this.userAddress,
                    JSON.stringify({
                        domain: {
                            chainId: this.chainId,
                            name: 'ORI',
                            verifyingContract: address,
                            version: '1',
                        },
                        message: {
                            holder: this.userAddress,
                            op: this.tokenOperatorAddress,
                            validAfter,
                            validBefore,
                            salt,
                        },
                        primaryType: 'AtomicApproveForAll',
                        types: {
                            AtomicApproveForAll: [
                                { name: 'holder', type: 'address' },
                                { name: 'op', type: 'address' },
                                { name: 'validAfter', type: 'uint256' },
                                { name: 'validBefore', type: 'uint256' },
                                { name: 'salt', type: 'bytes32' },
                            ],
                            EIP712Domain: [
                                { name: 'name', type: 'string' },
                                { name: 'version', type: 'string' },
                                { name: 'chainId', type: 'uint256' },
                                { name: 'verifyingContract', type: 'address' },
                            ],
                        },
                    }),
                ],
            });

            approves.push({
                token: address,
                from: this.userAddress,
                to: this.tokenOperatorAddress,
                validAfter,
                validBefore,
                salt,
                signature,
            });
        }

        return approves;
    }

    public async createDerivative(
        derivativeToken: string,
        derivativeAmount: number,
        licenses: [licenseToken: string, licenseTokenId: number | string][],
        approves: any[]
    ): Promise<any> {
        await this.switchChain();
        const approvesAbi = await this.tokenOperator.methods.receiveApproveAuthorization(approves).encodeABI();
        const createAbi = await this.tokenOperator.methods
            .createDerivative(
                derivativeToken,
                derivativeAmount,
                this.web3.eth.abi.encodeParameters(['tuple[](address,uint256)', 'uint256', 'uint256'], [licenses, 1, 0])
            )
            .encodeABI();
        const data = [];
        data.push(await this.web3.eth.abi.encodeParameters(['bytes', 'uint256'], [approvesAbi, 0]));
        data.push(await this.web3.eth.abi.encodeParameters(['bytes', 'uint256'], [createAbi, 0]));

        return this.particleProvider.request({
            method: 'eth_sendTransaction',
            params: [
                {
                    chainId: this.chainId,
                    from: this.userAddress,
                    value: '0x0',
                    nonce: '0x0',
                    to: this.tokenOperatorAddress,
                    data: await this.tokenOperator.methods.multicall(data).encodeABI(),
                },
            ],
        });
    }

    public async createDerivativeToNew(
        derivativeName: string,
        derivativeSymbol: string,
        derivativeAmount: number,
        licenses: [licenseToken: string, licenseTokenId: number | string][],
        approves: any[]
    ): Promise<any> {
        const approvesAbi = await this.tokenOperator.methods.receiveApproveAuthorization(approves).encodeABI();
        const createAbi = await this.tokenOperator.methods
            .createDerivativeToNew(
                derivativeName,
                derivativeSymbol,
                derivativeAmount,
                this.web3.eth.abi.encodeParameters(['tuple[](address,uint256)', 'uint256', 'uint256'], [licenses, 1, 0])
            )
            .encodeABI();
        const data = [];
        data.push(await this.web3.eth.abi.encodeParameters(['bytes', 'uint256'], [approvesAbi, 0]));
        data.push(await this.web3.eth.abi.encodeParameters(['bytes', 'uint256'], [createAbi, 0]));

        await this.switchChain();

        return this.particleProvider.request({
            method: 'eth_sendTransaction',
            params: [
                {
                    chainId: this.chainId,
                    from: this.userAddress,
                    value: '0x0',
                    nonce: '0x0',
                    to: this.tokenOperatorAddress,
                    data: await this.tokenOperator.methods.multicall(data).encodeABI(),
                },
            ],
        });
    }

    public async decodeCreateDerivativeToken(txnHash: string): Promise<any> {
        const receipt = await this.getTransactionReceipt(txnHash);

        return this.decodeMintEvent(receipt?.logs);
    }

    private async getLatestBlock(): Promise<any> {
        return this.web3.eth.getBlock('latest');
    }

    private async getTransactionReceipt(txnHash: string): Promise<any> {
        let receipt;
        while (!receipt) {
            await sleep(1000);
            try {
                receipt = await this.web3.eth.getTransactionReceipt(txnHash);
            } catch (error) {
                console.log('get receipt error: ', error);
            }
        }

        return receipt;
    }

    private decodeMintEvent(logs: any[]) {
        const mintAbi = TOKEN_OPERATOR_ABI.find((abi) => abi.type === 'event' && abi.name === 'Mint');
        const mintSign = this.web3.eth.abi.encodeEventSignature(mintAbi);

        for (const log of logs) {
            if (log.topics[0] === mintSign) {
                const decoded = this.web3.eth.abi.decodeLog(mintAbi.inputs, log.data, log.topics.slice(1));
                return { tokenId: decoded?.tokenId };
            }
        }

        return { tokenId: null };
    }
}

export function getMintFee(amount: number, expiredTime: number) {
    return new BN(0).add(
        new BN((1 * 1e12).toLocaleString('fullwide', { useGrouping: false }))
            .mul(new BN(amount))
            .mul(new BN(expiredTime))
            .div(new BN(86400))
    );
}

function sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

// Or2 Singleton Pattern
export class Ori2 {
    private static instance: Ori;
    private constructor() {}
    public static getInstance(particleProvider: Provider): Ori {
        if (!this.instance) {
            this.instance = new Ori(particleProvider);
        }
        return this.instance;
    }
}
