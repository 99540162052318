// import Link from 'next/link';
import MIcon from '@/components/mIcon';
import { useUserInfo } from '@/context/UserInfoContext';
import { useDeepCompareEffect, useScroll, useSize } from 'ahooks';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { formatAmount, getCurrentChain, isServer } from '@/utils/index';
import Link from 'next/link';
import { useRouter } from 'next/router';
// import styles from './style.module.less';
// import Blockies from 'react-blockies';
import Contact from '@/components/index/modules/contact';
import Search from './modules/search';
import useModal from '@/hooks/useModal';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Button, message } from 'antd';
import { ConnectButton, useAccount } from '@particle-network/connect-react-ui';
import { supportChains } from '@particle-network/common';

const UserInfoContent = () => {
    const { userInfo } = useUserInfo();

    const [networkName, setNetworkName] = useState('Mainnet');

    // useDeepCompareEffect(() => {
    //     (async () => {
    //         if (userInfo?.isLogin && userInfo?.address) {
    //             const { chainNetwork } = await getCurrentChain(userInfo.provider);
    //             setNetworkName(chainNetwork || process.env.NEXT_PUBLIC_CHAIN_NETWORK);
    //         } else {
    //             setNetworkName(process.env.NEXT_PUBLIC_CHAIN_NETWORK as string);
    //         }
    //     })();
    // }, [userInfo?.isLogin, userInfo?.address, userInfo?.provider]);

    // useDeepCompareEffect(() => {
    //     if (userInfo?.isLogin && userInfo?.provider) {
    //         userInfo.provider &&
    //             userInfo.provider.on('chainChanged', (chainId: any) => {
    //                 setNetworkName(
    //                     // @ts-ignore
    //                     supportChains.Ethereum.chainIds[Number(chainId)] || process.env.NEXT_PUBLIC_CHAIN_NETWORK
    //                 );
    //             });
    //     }
    // }, [userInfo?.isLogin, userInfo?.provider]);

    return (
        <div className="modal-userinfo-content">
            <div className="row row1">
                <div className="left">
                    <img src={userInfo.photo} alt="" />
                </div>
                <div className="right">
                    <div className="item-row1">
                        <div>Ethereum {userInfo?.chainInfo?.chainNetwork}</div>
                        <div className={'wallet-status ' + (userInfo.photo ? 'on-line ' : 'off-line')}></div>
                    </div>
                    <CopyToClipboard
                        text={userInfo.lookupAddress || userInfo.address}
                        onCopy={() => {
                            message.success('Copied to clipboard');
                        }}
                    >
                        <div className="item-row2">{userInfo.lookupAddress || userInfo.address.formatEthAddress()}</div>
                    </CopyToClipboard>
                </div>
            </div>
            <div className="row row2">
                <div className="label">Wallet Balance</div>
                <div className="value">{formatAmount(userInfo.balance || 0, 5)} ETH</div>
            </div>
            <div className="row row3">
                <CopyToClipboard
                    text={userInfo.lookupAddress || userInfo.address}
                    onCopy={() => {
                        message.success('Copied to clipboard');
                    }}
                >
                    <Button>
                        <MIcon name="icon58" />
                        <span>Copy</span>
                    </Button>
                </CopyToClipboard>

                {userInfo.logingType === 'particle' && (
                    <Button
                        onClick={() => {
                            // @ts-ignore
                            window?.connectKit?.particle?.openWallet?.();
                        }}
                    >
                        <MIcon name="icon62" />
                        <span>Wallet</span>
                    </Button>
                )}

                <Button
                    onClick={() => {
                        userInfo
                            .logout()
                            .then(() => {
                                // @ts-ignore
                                window.document.querySelector('.ant-modal-root')?.remove?.();
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    }}
                >
                    <MIcon name="icon59" />
                    <span>Disconnect</span>
                </Button>
            </div>
        </div>
    );
};

const Header = () => {
    const router = useRouter();

    const { userInfo } = useUserInfo();

    const scroll: any = useScroll(!isServer() ? document : null);

    const [mobileMenuVisible, setMobileMenuVisible] = useState(false);

    const bodySize = useSize(!isServer() ? document.body : null);

    const headerWrapperStyle = useMemo(() => {
        let opacityValue = 1;

        if (router.pathname == '/') {
            opacityValue = (scroll?.top || 0) / 200;
            opacityValue = opacityValue > 0.99 ? 0.99 : opacityValue;
        }

        return {
            backgroundColor: `rgba(29, 29, 34,${opacityValue})`,
        };
    }, [scroll?.top, router.pathname]);

    const headerClass = useMemo(() => {
        const className = ['header-container'];

        if (router.pathname == '/') {
            className.push('index-header');
        } else {
            className.push((router?.pathname || '').replace('/', '') + '-header');
        }

        return className.join(' ');
    }, [router.pathname]);

    const itemClass = useCallback(
        (name = '') => {
            return [router.pathname == name.replace('.html', '') ? 'activate' : '', 'item'].join(' ');
        },
        [router.pathname]
    );

    useEffect(() => {
        if (mobileMenuVisible) {
            document.body.classList.add('noscroll');
        } else {
            document.body.classList.remove('noscroll');
        }
    }, [mobileMenuVisible]);

    useEffect(() => {
        if (bodySize && bodySize?.width > 800 && mobileMenuVisible) {
            document.body.classList.remove('noscroll');
            setMobileMenuVisible(false);
        }
    }, [bodySize?.width, mobileMenuVisible]);

    const [UserInfoModal, { onOpen: UserInfoModalOnOpen }] = useModal(UserInfoContent, {
        className: 'modal-userinfo',
        width: 500,
        getContainer: false,
    });

    const menuList = useMemo(() => {
        return [
            {
                icon: 'icon11',
                href: '/explore.html',
                title: 'Explore',
            },
            {
                icon: 'icon22',
                href: '/profile.html',
                title: 'Profile',
            },
            {
                icon: 'icon07',
                href: '/create.html',
                title: 'Create',
            },
            // {
            //     icon: 'icon08',
            //     href: '/batch.html',
            //     title: 'Batch',
            // },
            {
                icon: 'icon09',
                href: '',
                title: 'Wallet',
                login: () => {
                    userInfo.login();
                },
            },
        ];
    }, []);
    const goItem = (item: any) => {
        // if (item.title == 'Profile') {
        //     localStorage.setItem('defaultActiveKey', '1');
        // }
        router.push({
            pathname: item.href,
        });
    };

    return (
        <div className={headerClass}>
            <div className={'wrapper'} style={headerWrapperStyle}>
                <div className="left">
                    <Link href="/">
                        <div className="logo">
                            <MIcon name="logo1" />
                        </div>
                    </Link>
                </div>
                <div className="middle">
                    <Search />
                </div>
                <div className="right m-right">
                    <div
                        className="menu-icon"
                        onClick={() => {
                            setMobileMenuVisible((val) => !val);
                        }}
                    >
                        <MIcon name="icon41" />
                    </div>
                    <div className={'wallet-status ' + (userInfo.photo ? 'on-line ' : 'off-line')}></div>
                </div>
                <div className="right">
                    <div className="operate list">
                        {menuList.map((item, index) => {
                            return (
                                <div key={index} data-index={index}>
                                    {
                                        item.title !== 'Wallet' ? (
                                            <a
                                                onClick={() => {
                                                    goItem(item);
                                                }}
                                            >
                                                <div className={itemClass(item.href)}>
                                                    <MIcon name={item.icon} />
                                                    <div className="title">{item.title}</div>
                                                </div>
                                            </a>
                                        ) : // Wallet start
                                        userInfo.photo ? (
                                            <div className="item user-photo-item" onClick={UserInfoModalOnOpen}>
                                                <img className="user-photo" src={userInfo.photo} alt="" />
                                            </div>
                                        ) : (
                                            <>
                                                <div
                                                    style={{
                                                        position: 'fixed',
                                                        top: '-99999px',
                                                        left: '-99999px',
                                                    }}
                                                >
                                                    <ConnectButton />
                                                </div>
                                                <ConnectButton.Custom>
                                                    {({ openConnectModal }) => {
                                                        return (
                                                            <div
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    openConnectModal();
                                                                }}
                                                            >
                                                                <div className="item">
                                                                    <MIcon name={item.icon} />
                                                                    <div className="title">{item.title}</div>
                                                                </div>
                                                            </div>
                                                        );
                                                    }}
                                                </ConnectButton.Custom>
                                            </>
                                        )
                                        // Wallet end
                                    }
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
            <div
                className={['mask', mobileMenuVisible ? 'is-open' : ''].join(' ')}
                data-aa={mobileMenuVisible ? 1 : 0}
            />
            <div className={['mobile-menu', mobileMenuVisible ? 'is-open' : ''].join(' ')}>
                <div className="wrap">
                    <div className="close-btn" onClick={() => setMobileMenuVisible((val) => !val)}>
                        <MIcon name="icon42" />
                    </div>
                    <div className="menu-list">
                        {menuList.map((item, index) => {
                            return (
                                <div key={index} data-index={index}>
                                    {
                                        item.title !== 'Wallet' ? (
                                            <Link href={item.href}>
                                                <a
                                                    onClick={() => {
                                                        setTimeout(() => {
                                                            setMobileMenuVisible(false);
                                                        });
                                                    }}
                                                >
                                                    <div className={itemClass(item.href)}>
                                                        <div className="title">{item.title}</div>
                                                        <MIcon name={item.icon} />
                                                    </div>
                                                </a>
                                            </Link>
                                        ) : // Wallet start
                                        userInfo.photo ? (
                                            <div className="item" onClick={UserInfoModalOnOpen}>
                                                <div
                                                    className={
                                                        'wallet-status ' + (userInfo.photo ? 'on-line ' : 'off-line')
                                                    }
                                                />
                                                <div className="address">{userInfo.address.formatEthAddress()}</div>
                                                <img className="user-photo" src={userInfo.photo} alt="" />
                                            </div>
                                        ) : (
                                            <ConnectButton.Custom>
                                                {({ openConnectModal }) => {
                                                    return (
                                                        <div
                                                            className="item"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                openConnectModal();
                                                            }}
                                                        >
                                                            <div
                                                                className={
                                                                    'wallet-status ' +
                                                                    (userInfo.photo ? 'on-line ' : 'off-line')
                                                                }
                                                            />
                                                            <div className="title">{item.title}</div>
                                                            <MIcon name={item.icon} />
                                                        </div>
                                                    );
                                                }}
                                            </ConnectButton.Custom>
                                        )
                                        // Wallet end
                                    }
                                </div>
                            );
                        })}
                    </div>
                    <Contact />
                    <Search setMobileMenuVisible={setMobileMenuVisible} />
                </div>
            </div>
            <div className="shim"></div>
            {UserInfoModal}
        </div>
    );
};

export default memo(Header);
