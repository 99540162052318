import { BN, unpadHexString } from 'ethereumjs-util';
import type { PrefixedHexString } from 'ethereumjs-util';

import numbro from 'numbro';
import BigNumber from 'bignumber.js';

export function formatTokenAmount(amount: number, decimals: number): string {
    if (!amount || !decimals) {
        return '0';
    }

    return numbro(amount / Math.pow(10, decimals)).format({
        thousandSeparated: true,
        mantissa: decimals,
        trimMantissa: true,
    });
}

export function toWei(amount: BN | string | number | undefined, unit = 'ether'): BN {
    if (!amount) {
        return new BN(0);
    }
    const ethjs = require('ethjs-unit');

    return ethjs.toWei(amount, unit);
}

export function fromWei(amount: BN | PrefixedHexString | undefined, unit = 'ether', mantissa = 6): string {
    if (!amount) {
        return '0';
    }
    const ethjs = require('ethjs-unit');
    let result;
    if (typeof amount === 'string' && amount.startsWith('0x')) {
        result = ethjs.fromWei(new BN(unpadHexString(amount), 16), unit);
    } else {
        result = ethjs.fromWei(amount, unit);
    }
    return numbro(result).format({
        thousandSeparated: true,
        trimMantissa: true,
        mantissa: mantissa,
    });
}

export function formatPrice(amount: string | number, price: number): string {
    const value = Number(amount) * price;
    return `≈$${numbro(value).format({
        thousandSeparated: true,
        trimMantissa: true,
        mantissa: 2,
    })}`;
}

export function trimDecimals(value: number | string, mantissa: number): string {
    return numbro(value).format({
        thousandSeparated: false,
        trimMantissa: true,
        mantissa: mantissa,
    });
}

/**
 * 9999999.99999999999  -->  9,999,999.99
 * @param amount
 * @param mantissa
 * @returns
 */
export const formatAmount = (amount: number | string = 0, mantissa = 4): string => {
    return new BigNumber(amount).toFormat(mantissa, 1).replace(/(?:\.0*|(\.\d+?)0+)$/, '$1');
};

// 233.43k
//  numbro(233434).format({
//     average: true,
//     mantissa: 2,
// })

// 1.99b
// numbro(1994444499).format({
//     average: true,
//     mantissa: 2,
// })
