/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useRequest } from 'ahooks';

import storage from '@/utils/storage';
import { getCollections } from '@/api/index';

import type { Collection } from '@/types/apis';
import { useRouter } from 'next/router';

const pollingInterval = 10 * 60 * 1000; //request every  10 minutes

type ContextType = Collection[] | null;

const whiteList = ['/', '/explore', '/collection', '/create', '/edit'];

const CollectionsContext = createContext<ContextType>(null);

export const useCollections = () => useContext(CollectionsContext);

export const CollectionsContextProvider = (props: any) => {
    const router = useRouter();

    const { data, run } = useRequest(getCollections, {
        pollingInterval: pollingInterval,
        manual: true,
    });

    const [finData, setFinData] = useState<ContextType>(null);

    const getLocalData = async () => {
        const localData: ContextType = await storage.getItem('collections');
        setFinData(localData || null);
    };

    //init
    useEffect(() => {
        getLocalData();
    }, []);

    useEffect(() => {
        if (data) {
            setFinData(data?.data);
            storage.setItem('collections', data?.data);
        }
    }, [data]);

    useEffect(() => {
        const findIndex = whiteList.findIndex((item) => item === router.pathname);
        if (findIndex >= 0) {
            run();
        }
    }, [router.pathname]);

    return <CollectionsContext.Provider value={finData}>{props.children}</CollectionsContext.Provider>;
};
