import MIcon from '@/components/mIcon';
import { useRouter } from 'next/router';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import qs from 'qs';
import { isEthAddress } from '@/utils/index';

const Search = (props: any) => {
    const router = useRouter();

    const [search, setSearch] = useState('');

    const inputRef = useRef<any>();

    const onSearch = useCallback(() => {
        if (isEthAddress(search)) {
            router.replace({
                pathname: '/collection.html',
                query: {
                    address: search,
                    contractType: 1,
                    source: 'search',
                },
            });
            return false;
        }

        if (router.pathname.includes('/explore')) {
            router.replace({
                pathname: '/explore.html',
                query: {
                    ...router.query,
                    q: search,
                },
            });
        } else {
            router.push({
                pathname: '/explore.html',
                query: {
                    q: search,
                },
            });
        }

        props.setMobileMenuVisible && props.setMobileMenuVisible(false);
    }, [search]);

    const onClear = useCallback(() => {
        setSearch('');
        inputRef.current.value = '';
        if (router.pathname.includes('/explore')) {
            router.replace({
                pathname: '/explore.html',
                query: {
                    ...router.query,
                    q: '',
                },
            });
        }
    }, [search, router.pathname]);

    useEffect(() => {
        setSearch((router?.query?.q || '') as string);
        if (inputRef.current) {
            inputRef.current.value = (router?.query?.q || '') as string;
        }
    }, [router?.query, router.pathname]);

    return (
        <div
            className="search-content"
            onKeyDown={(e) => {
                if (e.key === 'Enter') {
                    onSearch();
                }
            }}
        >
            <input
                ref={inputRef}
                type="text"
                className="input"
                placeholder="Search items, collections"
                onChange={(e) => {
                    setSearch(e.target.value);
                }}
            />

            {!!search && <MIcon name="icon42" className="allow-clear" onClick={onClear} />}

            <MIcon name="icon36" className="icon36" onClick={onSearch} />
        </div>
    );
};

export default memo(Search);
