import '../styles/globals.less';
import '../styles/styles.less';
import '@particle-network/connect-react-ui/dist/index.css';
import type { AppProps } from 'next/app';
// import dynamic from 'next/dynamic';
import { UserInfoContextProvider } from 'src/context/UserInfoContext';
import Header from '@/components/header';
import { KeepAliveProvider } from 'react-next-keep-alive';
import { CollectionsContextProvider } from '@/context/CollectionInfosContext';
import { memo, useEffect, useState } from 'react';
import { firebaseInit, isServer, sentryInit, setCacheData, getCacheData } from '@/utils/index';
import { ModalProvider } from '@particle-network/connect-react-ui';
import ApprovePopup from '@/components/approvePopup';

import {
    Provider,
    ParticleConnect,
    particleLocalEVM,
    metaMask,
    rainbow,
    walletconnect,
    evmWallets,
    ChainName,
} from '@particle-network/connect';

// import { EVMProvider } from '@particle-network/local-provider';
// const Dynamic = dynamic(import('../src/components/dynamic'), { ssr: false });

const MyApp = memo(({ Component, pageProps, router }: AppProps) => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!isServer()) {
            // // @ts-ignore
            // window.Sentry = sentryInit();
            // firebaseInit();

            const version = '0.0.2';
            if (localStorage.getItem('_version') !== version) {
                localStorage.clear();
                sessionStorage.clear();
                localStorage.setItem('_version', version);
            }

            // @ts-ignore
            window.__setCacheData = setCacheData;
            // @ts-ignore
            window.__getCacheData = getCacheData;
            // @ts-ignore
            // document?.querySelector('.sk-chase')?.remove?.();
        }
        console.log(process.env.NEXT_PUBLIC_CHAIN_ID as any);
        setTimeout(() => {
            setLoading(false);
        });
        // html overflow: initial

        setInterval(() => {
            if (document.querySelector?.('html')?.style) {
                // @ts-ignore
                document.querySelector('html').style.overflow = 'initial';
            }
        }, 300);
    }, []);

    console.log('>>>>>>>>>>>>>>>app render');

    return loading ? (
        <div className="sk-chase">
            <div className="sk-chase-dot"></div>
            <div className="sk-chase-dot"></div>
            <div className="sk-chase-dot"></div>
            <div className="sk-chase-dot"></div>
            <div className="sk-chase-dot"></div>
            <div className="sk-chase-dot"></div>
        </div>
    ) : (
        <ModalProvider
            theme="dark"
            walletSort={['Wallet', 'Particle Auth']}
            options={{
                projectId: process.env.NEXT_PUBLIC_PROJECT_ID as string,
                clientKey: process.env.NEXT_PUBLIC_PROJECT_CLIENT_KEY as string,
                appId: process.env.NEXT_PUBLIC_PROJECT_APP_ID as string,
                chains: [
                    {
                        name: process.env.NEXT_PUBLIC_CHAIN_NAME as ChainName,
                        id: Number(process.env.NEXT_PUBLIC_CHAIN_ID || 5),
                    },
                ],
                wallets: [
                    // particleLocalEVM({
                    //     package: EVMProvider,
                    // }),
                    metaMask({
                        qrcode: true,
                    }),
                    walletconnect({
                        qrcode: true,
                    }),
                    rainbow({
                        qrcode: true,
                    }),
                    // ...evmWallets({ qrcode: false }),
                ],
            }}
        >
            <UserInfoContextProvider>
                <CollectionsContextProvider>
                    <KeepAliveProvider router={router}>
                        <>
                            <Header />
                            {/* @ts-ignore */}
                            <Component router={router} pageProps={pageProps} />
                            <ApprovePopup />
                        </>
                    </KeepAliveProvider>
                </CollectionsContextProvider>
            </UserInfoContextProvider>
        </ModalProvider>
    );
});

const App = (props: AppProps) => {
    return <MyApp {...props} />;
};
export default App;
