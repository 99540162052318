import request from '@/utils/request';
export * from './profile';
export * from './supported-originals';
export * from './upload';

import type { Collection, Recommendation, NFTsCollectionQueryParams, NFT, NFTDetailParams } from '@/types/apis';

export const getCollections = () => {
    return request<Collection[]>({
        url: '/collections',
        method: 'get',
    });
};

export const getCollectionNFTs = (params: NFTsCollectionQueryParams) => {
    return request<NFT[]>({
        // url: `/nfts/collection?contractAddress=${params.contractAddress}`,
        url: `/nfts/collection?contractAddress=${params.contractAddress}&userWalletAddress=${params.userWalletAddress}`,
        method: 'get',
        // params,
    });
};

export const getRecommendationList = () => {
    return request<Recommendation[]>({
        url: '/collections/recommendation',
        method: 'get',
    });
};

export const getNFTDetail = (params: NFTDetailParams) => {
    return request<NFT>({
        url: '/nfts/info',
        method: 'get',
        params: params,
    });
};

export const checkCollectionExists = (params: { slug: string }) => {
    return request<any>({
        url: '/collections/exists',
        method: 'get',
        params: params,
    });
};

export const getDeployedDtokens = (params = {}) => {
    return request<any>({
        url: '/collections/deployed-dtokens',
        method: 'get',
        params,
    });
};

export const ercCheck = (params: { contractAddress: string }) => {
    return request<{
        erc721: boolean;
        erc1155: boolean;
    }>({
        url: '/nfts/erc_check',
        method: 'get',
        params: params,
    });
};

export const setTempMeta = (params: { meta: any }) => {
    return request<any>({
        url: '/nfts/set_temp_meta',
        method: 'post',
        data: params,
    });
};

export const updateMeta = (params: any) => {
    return request<any>({
        url: '/nfts/set_meta',
        method: 'post',
        data: params,
    });
};
