import axios from 'axios';
import type { AxiosRequestConfig, AxiosResponse } from 'axios';
import { toChecksumAddress } from 'ethereumjs-util';
import { message } from 'antd';
import storage from './storage';
import { signAccount } from '@/utils/index';

export const instance = axios.create({
    baseURL: process.env.NEXT_PUBLIC_ORI_URL as string,
    timeout: 30000,
});

export type Response<T> = {
    data: T;
    err_code?: number;
    error_code?: number;
    message: string;
};

const signatureErrorHandler = <T>(config: any, resolve: any, reject: any) => {
    // message.error('Signature error, please try again');
    const removeAddressSignature = () => {
        // @ts-ignore
        if (window?.userSignatureData?.addressSignatureKey) {
            // @ts-ignore
            return storage.removeItem(window.userSignatureData.addressSignatureKey);
        } else {
            return Promise.resolve();
        }
    };
    removeAddressSignature()
        .then(() => {
            signAccount()
                .then(() => {
                    // @ts-ignore
                    const { userAddress, signature, timestamp } = window?.userSignatureData || {};
                    config.headers = {
                        userAddress,
                        signature,
                        timestamp,
                    };

                    // if (userAddress && signature && timestamp) {
                    const url = new URL(config.url as string, process.env.NEXT_PUBLIC_ORI_URL as string);
                    url.searchParams.delete('userAddress');
                    url.searchParams.append('userAddress', userAddress || '');
                    url.searchParams.delete('signature');
                    url.searchParams.append('signature', signature || '');
                    url.searchParams.delete('timestamp');
                    url.searchParams.append('timestamp', timestamp || '');
                    config.url = url.toString();

                    // }
                    instance
                        .request<Response<T>>(config)
                        .then((response: AxiosResponse<Response<T>>) => {
                            resolve(response.data);
                        })
                        .catch((error) => {
                            reject(error);
                        });
                })
                .catch((err) => {
                    console.log(err);
                    reject(err);
                });
        })
        .catch((err) => {
            message.error(err.message);
            removeAddressSignature()
                .then()
                .catch((err) => {
                    console.log(err);
                });
            setTimeout(() => {
                location.reload();
            }, 400);
        });
};

export default <T>(config: AxiosRequestConfig) => {
    return new Promise<Response<T>>((resolve, reject) => {
        // @ts-ignore
        const { userAddress, signature, timestamp } = window?.userSignatureData || {};
        config.headers = {
            userAddress,
            signature,
            timestamp,
        };

        // if (userAddress && signature && timestamp) {
        const url = new URL(config.url as string, process.env.NEXT_PUBLIC_ORI_URL as string);
        url.searchParams.delete('userAddress');
        url.searchParams.append('userAddress', userAddress || '');
        url.searchParams.delete('signature');
        url.searchParams.append('signature', signature || '');
        url.searchParams.delete('timestamp');
        url.searchParams.append('timestamp', timestamp || '');
        config.url = url.toString();
        // }
        instance
            .request<Response<T>>(config)
            .then((response: AxiosResponse<Response<T>>) => {
                // address to toChecksumAddress
                if (response.data.data && typeof response.data.data === 'object') {
                    response.data.data = JSON.parse(
                        JSON.stringify(response.data.data).replace(/"0x\w{40}"/g, (val) => {
                            return `"${toChecksumAddress(val.replace(/"/g, ''))}"`;
                        })
                    );
                }
                if (
                    response?.data?.err_code === 60003 ||
                    response?.data?.err_code === 60002 ||
                    response?.data?.err_code === 10002
                ) {
                    signatureErrorHandler<T>(config, resolve, reject);
                } else {
                    resolve(response.data);
                }
            })
            .catch((error) => {
                reject(error);
            });
    });
};
