/* eslint-disable */
import icon01 from './icon01.svg';
import icon02 from './icon02.svg';
import icon03 from './icon03.svg';
import icon04 from './icon04.svg';
import icon05 from './icon05.svg';
import icon06 from './icon06.svg';
import icon07 from './icon07.svg';
import icon08 from './icon08.svg';
import icon09 from './icon09.svg';
import icon10 from './icon10.svg';
import icon11 from './icon11.svg';
import icon13 from './icon13.svg';
import icon14 from './icon14.svg';
import icon17 from './icon17.svg';
import icon18 from './icon18.svg';
import icon19 from './icon19.svg';
import icon20 from './icon20.svg';
import icon21 from './icon21.svg';
import icon22 from './icon22.svg';
import icon23 from './icon23.svg';
import icon24 from './icon24.svg';
import icon25 from './icon25.svg';
import icon26 from './icon26.svg';
import icon27 from './icon27.svg';
import icon28 from './icon28.svg';
import icon29 from './icon29.svg';
import icon30 from './icon30.svg';
import icon31 from './icon31.svg';
import icon32 from './icon32.svg';
import icon33 from './icon33.svg';
import icon34 from './icon34.svg';
import icon35 from './icon35.svg';
import icon36 from './icon36.svg';
import icon37 from './icon37.svg';
import icon38 from './icon38.svg';
import icon39 from './icon39.svg';
import icon40 from './icon40.svg';
import icon41 from './icon41.svg';
import icon42 from './icon42.svg';
import icon43 from './icon43.svg';
import icon44 from './icon44.svg';
import icon45 from './icon45.svg';
import icon46 from './icon46.svg';
import icon47 from './icon47.svg';
import icon48 from './icon48.svg';
import icon49 from './icon49.svg';
import icon50 from './icon50.svg';
import icon51 from './icon51.svg';
import icon52 from './icon52.svg';
import icon53 from './icon53.svg';
import icon54 from './icon54.svg';
import icon55 from './icon55.svg';
import icon56 from './icon56.svg';
import icon57 from './icon57.svg';
import icon58 from './icon58.svg';
import icon59 from './icon59.svg';
import icon60 from './icon60.svg';
import icon61 from './icon61.svg';
import icon62 from './icon62.svg';
import icon63 from './icon63.svg';
import icon64 from './icon64.svg';
import icon65 from './icon65.svg';
import logo1 from './logo1.svg';

export const iconList = ['icon01',
    'icon02',
    'icon03',
    'icon04',
    'icon05',
    'icon06',
    'icon07',
    'icon08',
    'icon09',
    'icon10',
    'icon11',
    'icon13',
    'icon14',
    'icon17',
    'icon18',
    'icon19',
    'icon20',
    'icon21',
    'icon22',
    'icon23',
    'icon24',
    'icon25',
    'icon26',
    'icon27',
    'icon28',
    'icon29',
    'icon30',
    'icon31',
    'icon32',
    'icon33',
    'icon34',
    'icon35',
    'icon36',
    'icon37',
    'icon38',
    'icon39',
    'icon40',
    'icon41',
    'icon42',
    'icon43',
    'icon44',
    'icon45',
    'icon46',
    'icon47',
    'icon48',
    'icon49',
    'icon50',
    'icon51',
    'icon52',
    'icon53',
    'icon54',
    'icon55',
    'icon56',
    'icon57',
    'icon58',
    'icon59',
    'icon60',
    'icon61',
    'icon62',
    'icon63',
    'icon64',
    'icon65',
    'logo1']

export default {
    icon01,
    icon02,
    icon03,
    icon04,
    icon05,
    icon06,
    icon07,
    icon08,
    icon09,
    icon10,
    icon11,
    icon13,
    icon14,
    icon17,
    icon18,
    icon19,
    icon20,
    icon21,
    icon22,
    icon23,
    icon24,
    icon25,
    icon26,
    icon27,
    icon28,
    icon29,
    icon30,
    icon31,
    icon32,
    icon33,
    icon34,
    icon35,
    icon36,
    icon37,
    icon38,
    icon39,
    icon40,
    icon41,
    icon42,
    icon43,
    icon44,
    icon45,
    icon46,
    icon47,
    icon48,
    icon49,
    icon50,
    icon51,
    icon52,
    icon53,
    icon54,
    icon55,
    icon56,
    icon57,
    icon58,
    icon59,
    icon60,
    icon61,
    icon62,
    icon63,
    icon64,
    icon65,
    logo1
};