import { uploadFile } from '@/api/index';
import { oneDayTime } from '@/components/nftDetails/components/nftMintModalContent';
import type { Profile } from '@/types/profile';
import axios from 'axios';
import BigNumber from 'bignumber.js';
import dayjs from 'dayjs';
import md5 from 'md5';
import numbro from 'numbro';
import type { UploadRequestOption } from 'rc-upload/lib/interface';
import { formatAmount } from './number-utils';
import storage from './storage';
import { v4 as uuidv4 } from 'uuid';
import { ParticleProvider } from '@particle-network/provider';
import { Chain, ParticleConnect } from '@particle-network/connect';
import { bufferToHex } from 'ethereumjs-util';
import { supportChains } from '@particle-network/common';

export * from './ethereum';
export * from './number-utils';
export * from './sentry';
export * from './firebase';

// @ts-ignore
String.prototype.formatEthAddress = function () {
    return this.slice(0, 6) + '...' + this.slice(-6);
};

export const expiredText = 'EXPIRED';

export const isServer = () => typeof window === 'undefined';

export const loadImg = async (src: string): Promise<HTMLImageElement> => {
    const img = new Image();
    img.setAttribute('crossOrigin', 'anonymous');
    img.src = src;
    return new Promise((resolve, reject) => {
        img.onload = () => {
            resolve(img);
        };
        img.onerror = (err) => {
            reject(err);
        };
    });
};

export const fileToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            resolve(reader.result as string);
        };
        reader.onerror = (error) => {
            reject(error);
        };
    });
};

export const base64ToFile = (base64: string, filename: string): File => {
    const arr = base64.split(',');
    // @ts-ignore
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
};

export const ipfsToSrc = (ipfs: string, size = '400x400') => {
    if (!ipfs) return '';
    if (ipfs.startsWith('ipfs://')) {
        return 'https://ipfs.particle.network/' + encodeURIComponent(ipfs.slice(7)) + '__' + size;
    }
    return ipfs;
};

export const generateLicenseImage = async (src: string, earnPoint: number | string, expiredTime: number) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
    const photoFrame = await loadImg('/photo_frame.png');
    const baseOriUrl = process.env.NEXT_PUBLIC_ORI_URL as string;
    const img = await loadImg(
        `${baseOriUrl}/image?url=${
            ipfsToSrc(src).includes('?') ? ipfsToSrc(src) + '&' : ipfsToSrc(src) + '?'
        }t=${Date.now()}`
    );
    const imgWidth = img.width;
    const imgHeight = img.height;
    const width = 800;
    const height = 800;
    let x = 0;
    let y = 0;
    if (imgWidth === imgHeight) {
        canvas.width = width;
        canvas.height = height;
        ctx.drawImage(img, 0, 0, width, height);
    } else if (imgWidth > imgHeight) {
        canvas.width = width;
        canvas.height = height;
        x = (imgWidth - imgHeight) / 2;
        ctx.drawImage(img, x, 0, imgHeight, imgHeight, 0, 0, width, height);
    } else {
        canvas.width = width;
        canvas.height = height;
        y = 0;
        ctx.drawImage(img, 0, y, imgWidth, imgWidth, 0, 0, width, height);
    }

    ctx.drawImage(photoFrame, 0, 0, width, height);
    const dateValue = dayjs(Date.now() + expiredTime * 1000).format('MM/DD/YYYY');

    const mendHeight = 24;
    {
        ctx.font = '32px Lexend';
        ctx.fillStyle = '#fff';
        ctx.fillText(`${Number(earnPoint) / 100}%`, 89, 655 + mendHeight);
        ctx.fillText(dateValue, 309, 655 + mendHeight);
        ctx.fillText('OriNFT.', 541, 655 + mendHeight);
    }
    {
        ctx.font = '300 29px Lexend';
        ctx.fillStyle = '#fff';
        ctx.fillText('xyz', 661, 655 + mendHeight);
    }
    {
        ctx.font = '100 24px Lexend';
        ctx.fillStyle = 'rgba(255, 255, 255, 0.5)';
        ctx.fillText('Author earning', 89, 694 + mendHeight);
        ctx.fillText('Expiration date', 309, 694 + mendHeight);
        ctx.fillText('Website', 541, 694 + mendHeight);
    }

    const base64 = canvas.toDataURL();
    const file = base64ToFile(base64, 'image.png');
    const res: any = await uploadFile({
        file,
    } as UploadRequestOption);

    if (res.err_code == 0 && res?.data?.url) {
        return res.data.url;
    } else {
        throw new Error(res.message || 'Failed to upload image');
    }
};

export const generateLicenseMetaData = async (
    originalData: any,
    licenseTokenAddress: string,
    licenseTokenId: string | number,
    earnPoint: string | number,
    expiredTime: number
) => {
    const name = `${
        originalData?.name || originalData?.data?.name || originalData?.data?.asset_contract?.name || ''
    } - LIC #${licenseTokenId}`
        .trim()
        .replace(/\s{2}/g, ' ');

    const description = (
        'This NFT is a License NFT, which provides copyright to the Derivative NFT. You can create Derivative NFTs in ORI. ' +
        (originalData?.data?.description || '')
    ).trim();

    const external_url = `${
        process.env.NEXT_PUBLIC_WEBSITE_URL as string
    }/nftDetails.html?contractAddress=${licenseTokenAddress}&tokenId=${licenseTokenId}&contractType=1`;

    let image;
    const oSrc = originalData.image || originalData?.data?.image_url || originalData?.data?.asset_contract?.image_url;

    if (!oSrc) {
        throw new Error('Image not found');
    }

    try {
        image = await generateLicenseImage(oSrc, earnPoint, expiredTime);
    } catch (error) {
        console.log(error);
    }

    const data = {
        name,
        description,
        image,
        external_url,
        nftType: 'license',
        originalToken: originalData?.data?.asset_contract?.address || originalData?.data?.address,
        originalTokenId: originalData?.data?.token_id,
        attributes: [
            {
                display_type: 'boost_percentage',
                trait_type: 'earning',
                value: Number(new BigNumber(earnPoint).dividedBy(100).toFixed(2)),
                max_value: 0.1 * 100,
            },
            {
                trait_type: 'ONFT',
                value: '#' + originalData?.data?.token_id,
            },
            {
                trait_type: 'Expiration Date',
                value: dayjs(Date.now() + expiredTime * 1000).format('MM/DD/YYYY'),
            },
        ],
    };
    console.log(data);
    return data;
};

export const getCacheData = () => {
    const cacheData = {
        sessionStorage: Object.keys(sessionStorage).map((key) => [key, sessionStorage[key]]),
        localStorage: Object.keys(localStorage).map((key) => [key, localStorage[key]]),
    };
    const encodeCacheData = encodeURIComponent(JSON.stringify(cacheData));
    return encodeCacheData;
};

export const setCacheData = (encodeCacheData: string) => {
    const { sessionStorage: sessionStorageList, localStorage: localStorageList } = JSON.parse(
        decodeURIComponent(encodeCacheData)
    );
    sessionStorageList.forEach((item: any) => {
        sessionStorage.setItem(item[0], item[1]);
    });
    localStorageList.forEach((item: any) => {
        localStorage.setItem(item[0], item[1]);
    });
};

/**
 *
 * // format expired time
 * @param {number} lastSeconds
 */
export const formatExpiredTime = (lastSeconds: number) => {
    if (lastSeconds === undefined || lastSeconds === null) return;
    if (lastSeconds < 0) {
        return expiredText;
    } else {
        if (lastSeconds > oneDayTime) {
            const days = Math.floor(lastSeconds / oneDayTime);
            return `${days} day${days > 1 ? 's' : ''}`;
        } else {
            return numbro(lastSeconds).format({
                output: 'time',
            });
        }
    }
};

export const isEthAddress = (address: string) => {
    return /^0x[a-fA-F0-9]{40}$/.test(address);
};

export const sleep = (ms: number) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
};

export async function signMessage(provider: any, userInfo: any, message: string) {
    const msg = bufferToHex(Buffer.from(message, 'utf8'));
    console.log('msg', msg);

    return provider.request({
        method: 'personal_sign',
        params: [msg, userInfo.address],
        from: userInfo.address,
    });
}

// user addressSignature
export const signAccount = async () => {
    // @ts-ignore
    const provider = window?.__particleProvider;
    // @ts-ignore
    const account = window?.__userInfo?.address;
    if (!account || !provider) {
        console.log('no account or provider');
        return;
    }

    const userAddress = `${account.toLowerCase()}`;
    const timestamp = `${Math.floor(Date.now() / 1000)}`;
    // const messageValue = `ori:${account.toLowerCase()}:${timestamp}`;
    let messageValue = `Welcome to OriNFT !
Click to sign in OriNFT.
    
This request will not trigger a blockchain transaction or cost any gas fees.
    
Your authentication status will reset after 7 days.
    
Wallet address:${account.toLowerCase()}
    
Nonce:${uuidv4()}

Timestamp:${timestamp}`;

    messageValue = `Welcome to OriNFT !\nClick to sign in OriNFT.\n\nThis request will not trigger a blockchain transaction or cost any gas fees.\n\nYour authentication status will reset after 7 days.\n\nWallet address:${userAddress}\n\nNonce:302708c4-0980-4544-8202-dac122f46bc3\n\nTimestamp:${timestamp}`;

    console.log('messageValue', messageValue);

    const key = md5(`ori:${userAddress?.toLowerCase()}`);
    const addressSignatureKey = `accountSignature-${key}`;

    let signatureData: any = (await storage.getItem(addressSignatureKey)) || {};
    if (
        !signatureData?.signature ||
        (signatureData?.timestamp && Math.floor(Date.now() / 1000) - signatureData.timestamp > 7 * 24 * 60 * 60)
    ) {
        try {
            const signature = await signMessage(provider, { address: account }, messageValue);
            signatureData = {
                userAddress,
                timestamp,
                signature,
                addressSignatureKey,
            };
            await storage.setItem(addressSignatureKey, signatureData);
        } catch (error) {
            console.log(error);
            throw new Error('Sign failed');
        }
    }

    // @ts-ignore
    window.userSignatureData = signatureData;

    // __storage.setItem('accountSignature-f4261819d03d4d12555052a5a783bfff', {
    //     userAddress: '0xfaa0f1f5dc5b0092730c5519afa9e93a9ec14a8e',
    //     timestamp: 11,
    //     signature:
    //         '0x27950a77eb3465904bda0442a997944964a488afb36a8faab939c6713d56a5267745b40f6a8e91d385a95eb3066339532d2f716f323cef8c6c836d24b176bcd71b',
    //     addressSignatureKey: 'accountSignature-f4261819d03d4d12555052a5a783bfff',
    // });
    // userSignatureData.timestamp='11'

    return signatureData;
};

export const getUserSignature = async (address: string) => {
    try {
        const key = md5(`ori:${address?.toLowerCase()}`);
        const addressSignatureKey = `accountSignature-${key}`;
        let signatureData: any = (await storage.getItem(addressSignatureKey)) || {};
        if (signatureData.timestamp && Math.floor(Date.now() / 1000) - signatureData.timestamp > 7 * 24 * 60 * 60) {
            signatureData = {};
        }
        // @ts-ignore
        window.userSignatureData = signatureData;
    } catch (error) {
        console.log(error);
    }
};

export const getEthPrice = async () => {
    return axios
        .post(
            'https://api.particle.network/evm-chain/rpc',
            {
                chainId: 1,
                jsonrpc: '2.0',
                id: 1,
                method: 'particle_getPrice',
                params: [['native'], ['usd']],
            },
            {
                auth: {
                    username: '35f69e05-c675-4e7f-9f2f-b75ce26bbaef',
                    password: 'cZkbObiRX3o9XWkl3Y6S5XjubDzoRdl9v1nnW34h',
                },
            }
        )
        .then((res) => {
            console.log('getEthPrice', res);
            return res?.data?.result?.[0]?.currencies?.[0]?.price || 0;
        });
};

export const switchChain = async (particleProvider: ParticleProvider) => {
    if (!particleProvider) {
        return;
    }
    let providerChainId = await particleProvider.request({ method: 'eth_chainId' });
    if (!!providerChainId && providerChainId.toString().startsWith('0x')) {
        providerChainId = parseInt(providerChainId, 16);
    }

    const targetChainId = Number(process.env.NEXT_PUBLIC_CHAIN_ID);

    console.log(
        'providerChainId',
        providerChainId,
        'targetChainId',
        targetChainId,
        'isSwitchChainResult',
        // @ts-ignore
        !!window?.switchChainResult
    );

    if (providerChainId !== targetChainId) {
        // @ts-ignore
        if (!window.switchChainResult) {
            //  @ts-ignore
            window.switchChainResult = new Promise(async (resolve, reject) => {
                if (!particleProvider.isParticleNetwork && isMobile()) {
                    await new Promise((resolve, reject) => {
                        try {
                            // @ts-ignore
                            window.__userInfo.events.emit('event:switchChain:approve');
                            // @ts-ignore
                            window.__userInfo.events.on('event:switchChain:execute', () => {
                                resolve(true);
                            });
                        } catch (error) {
                            reject(error);
                        }
                    });
                }
                const params = {
                    id: targetChainId,
                    name: process.env.NEXT_PUBLIC_CHAIN_NAME,
                };

                console.log('connectKit.switchChain', params);

                // @ts-ignore
                window.connectKit
                    .switchChain(params)
                    .then(() => {
                        setTimeout(() => {
                            // @ts-ignore
                            window.switchChainResult = null;
                            console.log('switchChainSuccess');
                        }, 1000);
                        // @ts-ignore
                        window.__userInfo.events.emit('event:switchChain:success');
                        resolve(true);
                    })
                    .catch((error: any) => {
                        setTimeout(() => {
                            // @ts-ignore
                            window.switchChainResult = null;
                            console.log('switchChainFail');
                        }, 1000);
                        reject(error);
                    });
            });
        }

        // @ts-ignore
        return window.switchChainResult;
    }
};

/**
 * portal 错误信息翻译功能
 * @param e 错误信息
 * @param market 市场，用于拿去其中提示的市场
 * @returns 提示文字
 */
export const portalErrorTranslation = (e: any) => {
    const reason = e?.reason ? 'execution reverted:' + e?.reason : '';
    const errMessage = reason || e?.message || e?.error?.message || e || 'Unknown error';
    return errMessage;
};
export const isMobile = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
};

export const maxZIndex = (): number => {
    const zIndexList = Array.from(document.querySelectorAll('*')).map((a) => {
        return Number(window.getComputedStyle(a)?.zIndex || 0) || 0;
    });
    return Math.max(...zIndexList) || 0;
};

export const getCurrentChain = async (particleProvider: ParticleProvider) => {
    if (!particleProvider) {
        return {
            chainId: 0,
            chainName: '',
            chainNetwork: '',
        };
    }

    let chainId = await particleProvider.request({ method: 'eth_chainId' });
    if (!!chainId && chainId.toString().startsWith('0x')) {
        chainId = parseInt(chainId, 16);
    }

    return {
        chainId,
        chainName: 'Ethereum',
        chainNetwork: supportChains.Ethereum.chainIds[chainId] || 'Mainnet',
    };
};
