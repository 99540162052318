/* eslint-disable @typescript-eslint/no-explicit-any */
import { useBoolean } from 'ahooks';
import { Modal } from 'antd';
import { useEffect, useState } from 'react';

import modalClose from '@/assets/svgs/modal_close.svg';

import type { ModalProps } from 'antd';

export interface ModalContentProps<T = any> {
    data: T;
    isOpen?: boolean;
    onClose?: () => void;
    onOpen?: () => void;
}

export interface NModalProps extends ModalProps {
    data?: any;
}

const useModal = (
    ModalContent: any,
    opts: Partial<NModalProps> = {}
): [
    any,
    {
        isOpen: boolean;
        onOpen: (info?: any) => void;
        onClose: () => void;
    }
] => {
    const [isOpen, { setTrue, setFalse }] = useBoolean();
    const [data, setData] = useState<any>();

    useEffect(() => {
        if (!opts.data) return;
        setData(opts.data);
    }, [opts.data]);

    const onOpen = (res?: any) => {
        setData(res);
        setTrue();
    };
    const ThisModal = isOpen ? (
        <Modal
            bodyStyle={{
                backgroundColor: '#23262F',
                borderRadius: '10px',
            }}
            wrapClassName="normal-modal"
            data={data}
            {...opts}
            visible={isOpen}
            closeIcon={<img onClick={setFalse} className="modal-close" src={modalClose} />}
            footer={null}
        >
            <ModalContent data={data} isOpen={isOpen} onClose={setFalse} />
        </Modal>
    ) : (
        <></>
    );

    return [
        ThisModal,
        {
            isOpen,
            onOpen,
            onClose: setFalse,
        },
    ];
};
export default useModal;
