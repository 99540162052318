import React, { createContext, useContext, useEffect, useState } from 'react';
import { useSetState, useLocalStorageState, useDeepCompareEffect, useDebounceEffect } from 'ahooks';
import makeBlockie from 'ethereum-blockies-base64';
import { useRouter } from 'next/router';
import { getBalance } from '@/utils/ethereum';
import { ethers } from 'ethers';
import Web3 from 'web3';
import { ParticleProvider } from '@particle-network/provider';
import { ParticleNetwork } from '@particle-network/auth';
import { toChecksumAddress } from 'ethereumjs-util';
import {
    useAccount,
    useParticleConnect,
    useParticleProvider,
    useConnectModal,
    useModalState,
    useConnectKit,
} from '@particle-network/connect-react-ui';
import { debounce } from 'lodash';
import storage from '@/utils/storage';
import { message } from 'antd';
import { ChainName } from '@particle-network/connect';
import { getCurrentChain, getEthPrice, getUserSignature, signAccount, switchChain } from '@/utils/index';
import { EventEmitter } from 'events';

interface UserInfoData {
    photo: string;
    address: string;
    balance: string;
    isLogin: boolean;
    provider: any;
    lookupAddress: string;
    logingType: string;
    pn?: any;
    providerChainId?: number;
    ethPrice: number;
    chainInfo: any;
    events: EventEmitter;
    getProvider: () => any;
    login: () => void;
    logout: () => void;
}

const defaultData: UserInfoData = {
    photo: '',
    address: '',
    balance: '',
    isLogin: false,
    provider: null,
    pn: null,
    lookupAddress: '',
    logingType: '',
    providerChainId: 0,
    ethPrice: 0,
    chainInfo: {},
    events: new EventEmitter(),
    getProvider: () => {},
    login: () => {},
    logout: () => {},
};

let ethersProvider: ethers.providers.JsonRpcProvider;

const whiteList = ['/', '/explore', '/demo1', '/iconDemo', '/collection'];

const UserInfoContext = createContext({});

let loginTimer: any;

export const useUserInfo = () => useContext(UserInfoContext) as any;

export const UserInfoContextProvider = (props: any) => {
    const router = useRouter();
    const [isAuthLogin, setAuthLogin] = useLocalStorageState<boolean | undefined>('authLogin');
    const [userInfo, setUserInfo] = useSetState<UserInfoData>(defaultData);
    const provider: any = useParticleProvider();
    const account = useAccount();
    const { disconnect } = useParticleConnect();
    const { openConnectModal } = useConnectModal();
    const { connectModalOpen } = useModalState();
    const [loginWait, setLoginWait] = useState(false);
    const connectKit = useConnectKit();

    if (typeof window !== 'undefined') {
        // @ts-ignore
        window.connectKit = connectKit;
        // @ts-ignore
        window.particleProvider = provider;
        // @ts-ignore
        window.__particleProvider = provider;
        // @ts-ignore
        window.__switchChain = switchChain;
    }

    useDeepCompareEffect(() => {
        (async () => {
            if (account) {
                const _account = toChecksumAddress(account);
                console.log('account', account, _account);

                const events = new EventEmitter();

                const userInfo = {
                    isLogin: true,
                    address: _account,
                    photo: makeBlockie(_account),
                    logingType: localStorage.getItem('particle_connect_cached_provider') as string,
                    provider,
                    events,
                    // providerChainId,
                    // pn,
                };

                setUserInfo(userInfo);

                // signAccount();
                // let rpc =
                //     'https://rpc.particle.network/evm-chain/?chainId=5&projectUuid=772f7499-1d2e-40f4-8e2c-7b6dd47db9de&projectKey=ctWeIc2UBA6sYTKJknT9cu9LBikF00fbk1vmQjsV';

                // rpc =
                //     'https://rpc-debug.particle.network/evm-chain/?chainId=5&projectUuid=5caf6169-dc05-4048-abc9-de77a3e237b0&projectKey=c2neqwbbZCqi5b7Of19EZ20g9D9fVFDeEhMtRmdr';

                // const _rpc = `${process.env.NEXT_PUBLIC_RPC_URL}/evm-chain/?chainId=${process.env.NEXT_PUBLIC_CHAIN_ID}&projectUuid=${process.env.NEXT_PUBLIC_PROJECT_ID}&projectKey=${process.env.NEXT_PUBLIC_PROJECT_CLIENT_KEY}`;

                // ethersProvider = new ethers.providers.JsonRpcProvider(rpc);

                try {
                    ethersProvider = new ethers.providers.Web3Provider(provider);
                    ethersProvider &&
                        ethersProvider
                            .lookupAddress(account)
                            .then((res: string | null) => {
                                setUserInfo({
                                    lookupAddress: res || '',
                                });
                            })
                            .catch((err) => {
                                console.log('lookupAddressError', err);
                            });
                } catch (error) {
                    console.log('ethersProvider', error);
                }

                // @ts-ignore
                // window.ethersProvider = ethersProvider;

                // 0x5555763613a12D8F3e73be831DFf8598089d3dCa
                // 0xe4a0e05cb060a0423d14c7878af6980dd0eaa014

                // @ts-ignore
                window.__userInfo = userInfo;

                setAuthLogin(true);

                setTimeout(() => {
                    switchChain(provider)
                        .then((res) => {})
                        .catch((err) => {
                            console.log(err);
                            message.error('Switch chain failed');
                        });
                }, 1000);

                getUserSignature(account);
            } else {
                setUserInfo({
                    isLogin: false,
                    address: '',
                    photo: '',
                    provider: null,
                });
            }
        })();
    }, [account, provider]);

    useEffect(() => {
        loginTimer && clearTimeout(loginTimer);
        loginTimer = setTimeout(() => {
            if (isAuthLogin && !account) {
                setAuthLogin(false);
            }
        }, 1000);
    }, [isAuthLogin, account]);

    useEffect(() => {
        if (!isAuthLogin && !whiteList.includes(router.pathname.replace('.html', ''))) {
            if (openConnectModal) {
                openConnectModal();
                setLoginWait(true);
            }
        }
    }, [isAuthLogin, router.pathname]);

    useDebounceEffect(
        () => {
            // 弹出登录弹窗，如果点击取消，就跳转到首页
            if (
                loginWait &&
                !connectModalOpen &&
                !whiteList.includes(router.pathname.replace('.html', '')) &&
                !account
            ) {
                router.replace('/');
            }
            if (loginWait && !connectModalOpen) {
                setLoginWait(false);
            }
        },
        [connectModalOpen, account],
        {
            wait: 300,
        }
    );

    useDeepCompareEffect(() => {
        if (userInfo?.address && provider) {
            getBalance(userInfo?.address, provider)
                .then((res) => {
                    userInfo.balance = res;
                    setUserInfo(userInfo);
                })
                .catch((err) => {
                    console.log(err);
                });
            getCurrentChain(provider)
                .then((res) => {
                    userInfo.chainInfo = res;
                    setUserInfo(userInfo);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, [userInfo?.address, provider]);

    // useDeepCompareEffect(() => {
    //     //@ts-ignore
    //     window.__particleProvider = provider;
    //     //@ts-ignore
    //     window.particleProvider = provider;

    //     // if (provider?.auth?.setAuthTheme) {
    //     //     provider.auth.setAuthTheme({
    //     //         uiMode: 'dark',
    //     //         displayCloseButton: true,
    //     //         displayWallet: false,
    //     //     });
    //     // }
    // }, [provider]);

    useDeepCompareEffect(() => {
        //@ts-ignore
        window.__userInfo = userInfo;
    }, [userInfo]);

    useEffect(() => {
        // 切换网络 更新余额
        async function chainChanged(chain: any) {
            if (userInfo?.address && provider) {
                getBalance(toChecksumAddress(userInfo.address), provider)
                    .then((res) => {
                        userInfo.balance = res;
                        setUserInfo(userInfo);
                    })
                    .catch((err) => {
                        console.log(err);
                    });

                getCurrentChain(provider)
                    .then((res) => {
                        userInfo.chainInfo = res;
                        setUserInfo(userInfo);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        }
        connectKit.on('chainChanged', chainChanged);
        return () => {
            connectKit.removeListener('chainChanged', chainChanged);
        };
    }, [connectKit, provider, userInfo]);

    useEffect(() => {
        userInfo.login = async () => {
            console.log('login');
            if (openConnectModal) {
                openConnectModal();
                setLoginWait(true);
            }
        };

        userInfo.logout = async () => {
            await disconnect();
            localStorage.clear();
            sessionStorage.clear();
            localStorage.setItem('_version', '0.0.2');
            window.location.replace('/');
            setTimeout(() => {
                setAuthLogin(undefined);
            }, 800);
            return true;
        };

        userInfo.getProvider = () => {
            let provider = null;

            if (typeof window !== 'undefined') {
                // @ts-ignore
                if (!window.__particleProvider) {
                    const pn = new ParticleNetwork({
                        projectId: process.env.NEXT_PUBLIC_PROJECT_ID as string,
                        clientKey: process.env.NEXT_PUBLIC_PROJECT_CLIENT_KEY as string,
                        appId: process.env.NEXT_PUBLIC_PROJECT_APP_ID as string,
                        chainName: process.env.NEXT_PUBLIC_CHAIN_NAME as string,
                        chainId: Number(process.env.NEXT_PUBLIC_CHAIN_ID as string),
                        authUrl: process.env.NEXT_PUBLIC_AUTH_URL as string,
                        wallet: {
                            displayWalletEntry: false,
                        },
                    } as any);

                    // @ts-ignore
                    return new ParticleProvider(pn.auth);
                }
                // @ts-ignore
                provider = window.__particleProvider;
            }

            return provider;
        };

        //@ts-ignore
        window.__router = router;
        // @ts-ignore
        window.__storage = storage;

        setUserInfo(userInfo);
    }, []);

    return <UserInfoContext.Provider value={{ userInfo, setUserInfo }}>{props.children}</UserInfoContext.Provider>;
};
