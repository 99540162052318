import React, { useEffect, useMemo, useState } from 'react';
import { Button, Col, Form, Row, message, Tooltip, Spin } from 'antd';
import { every, values } from 'lodash';

import InfoItem from '@/components/infoItem';
import Input from '@/components/input';
import { fromWei } from '@/utils/number-utils';
import { generateLicenseMetaData, ipfsToSrc, portalErrorTranslation } from '@/utils/index';
import type { Profile } from '@/types/profile';
import { useRouter } from 'next/router';
import BigNumber from 'bignumber.js';
import { validate, reg } from '@/utils/reg';
import { getMintFee, Ori2 } from '@/utils/ori2';
import { useUserInfo } from '@/context/UserInfoContext';

import waterMark from '@/assets/svgs/water_mark.svg';
import help from '@/assets/svgs/help_icon.svg';
import eth from '@/assets/svgs/eth.svg';

import type { ModalContentProps } from '@/hooks/useModal';

import type { NFT } from '@/types/apis';

import styles from './style.module.less';
import { useRequest } from 'ahooks';
import { ercCheck } from '@/api/index';

export interface ContentInfoProps {
    imgSrc?: string;
    desc: string;
    name: string;
    showIcon?: boolean;
}

export interface MintInfoProps {
    value: string;
}
export interface FormFields {
    amount: number;
    earnPoint: number;
    expiredDay: number;
}

export const oneDayTime = 60 * 60 * 24;
export const earnPointUnit = 10000 / 100;
const minShowFeeValue = 0.000001;

export const ContentInfo = ({ imgSrc, desc, name, showIcon = false }: ContentInfoProps) => {
    return (
        <div className={styles.infoContainer}>
            <div className={styles.image}>
                {imgSrc && <img src={imgSrc} alt="" />}
                {showIcon && <img className={styles.icon} src={waterMark} alt="" />}
            </div>
            <div className={styles.info}>
                <Tooltip
                    title={desc}
                    overlayInnerStyle={{
                        width: '600px',
                    }}
                >
                    <div className={styles.desc}>{desc || '--'}</div>
                </Tooltip>

                <div className={styles.name}>{name || '--'}</div>
            </div>
        </div>
    );
};

export const MintInfo = ({ value }: MintInfoProps) => {
    console.log('value', value);
    const culValue = useMemo(() => {
        if (!value || value === '--') return '--';
        return new BigNumber(value).gte(minShowFeeValue) ? value : `< ${minShowFeeValue}`;
    }, [value]);
    return (
        <div className={styles.mint}>
            <Tooltip title="Mint Fee=(expired day) *  (mint amount) * 0.000001 ETH">
                <div className={styles['mint-label']}>
                    <span>Mint fee</span>
                    <img src={help} alt="" />
                </div>
            </Tooltip>

            <div className={styles['mint-value']}>
                <img src={eth} alt="" />
                <span>{culValue}</span>
            </div>
        </div>
    );
};

const validateValues = (values: FormFields) => {
    const validateAmount = validate(values.amount, reg.positiveInteger);
    const validateEarnPoint = validate(values.earnPoint, reg.positiveFloatTwo);
    const validateExpiredDay = validate(values.expiredDay, reg.positiveInteger);

    if (!validateEarnPoint) {
        message.warning('Author earn must be an integer with no more than 2 decimal places');
        return;
    }
    if (!validateAmount) {
        message.warning('Amount must be positive integer');
        return;
    }
    if (!validateExpiredDay) {
        message.warning('Expired after must be positive integer');
        return;
    }

    if (values.earnPoint > 10) {
        // eslint-disable-next-line quotes
        message.warning("Author earn point can't over 10%");
        return;
    }

    return true;
};

const loopEnable = async (ori: any, address: string) => {
    let isEnable = await ori.tokenEnabled(address);
    if (!isEnable) {
        isEnable = await loopEnable(ori, address);
    }
    return isEnable;
};

function NFTMintModalContent({
    data,
    onClose,
}: ModalContentProps<{
    nftInfo: NFT;
    [key: string]: any;
}>) {
    const router = useRouter();
    const { userInfo } = useUserInfo();
    const { nftInfo, refreshInfo } = data;
    const [form] = Form.useForm<FormFields>();
    const [loading, setLoading] = useState(false);

    const { data: ercCheckData, runAsync: ercCheckRunAsync } = useRequest(ercCheck, {
        manual: true,
    });

    const amount = Form.useWatch('amount', form);
    const expiredDay = Form.useWatch('expiredDay', form);

    const isEvery = every(values, Boolean);

    const handleMint = async (values: FormFields) => {
        try {
            console.log('ercCheckData', ercCheckData);
            // @ts-ignore
            // if (!ercCheckData?.erc721) {
            //     message.warning('Minting license for ERC1155 NFT  is not yet supported');
            //     return;
            // }
            setLoading(true);

            const validate = validateValues(values);
            if (!validate) return;

            const ori = Ori2.getInstance(userInfo.provider);
            const expiredTime = values.expiredDay * oneDayTime;
            const earnPoint = new BigNumber(values.earnPoint).multipliedBy(earnPointUnit).toFixed(0);

            // return false;
            // const deploy = await ori.deploy(
            //     nftInfo?.asset_contract?.address,
            //     DERIVATIVE_TOKEN_TYPE.ERC721,
            //     `Derivative of <${nftInfo?.asset_contract?.name}>`,
            //     `d${nftInfo?.asset_contract?.symbol}`
            // );

            // const isEnabled = await loopEnable(ori, deploy?.license);
            // if (isEnabled) {
            await ori.createLicense(
                nftInfo?.asset_contract?.address,
                nftInfo?.token_id || nftInfo?.tokenId,
                values.amount,
                earnPoint,
                expiredTime
            );

            localStorage.setItem('defaultActiveKey', '2');
            await refreshInfo();
            router.push({
                pathname: '/profile.html',
            });
            message.success('Mint success');
            onClose && onClose();
            // }
        } catch (error) {
            const errorInfo = portalErrorTranslation(error);
            message.error(errorInfo);
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const minFee = useMemo(() => {
        if (!amount || !expiredDay) return '--';
        const value = getMintFee(amount, expiredDay * oneDayTime);
        return fromWei(value?.toString());
    }, [amount, expiredDay]);

    useEffect(() => {
        if (!nftInfo?.asset_contract?.address) return;
        ercCheckRunAsync({
            contractAddress: nftInfo?.asset_contract?.address as string,
        });
    }, [nftInfo]);

    return (
        <>
            {loading && (
                <Spin
                    spinning={loading}
                    wrapperClassName="model-loading"
                    tip="Processing transactions, Do not close this modal"
                >
                    &nbsp;
                </Spin>
            )}

            <Form form={form} onFinish={handleMint} autoComplete="off">
                <div
                    className={styles.box}
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                >
                    <div className={styles.title}>New license NFT</div>
                    <ContentInfo
                        imgSrc={ipfsToSrc(nftInfo?.image_url || nftInfo?.asset_contract?.image_url)}
                        desc={nftInfo?.description || '--'}
                        name={nftInfo?.asset_contract?.name || '--'}
                        showIcon={false}
                    />
                    <Row
                        className={styles.infos}
                        gutter={[
                            30,
                            {
                                xs: 20,
                                md: 0,
                            },
                        ]}
                    >
                        <Col xs={{ span: 10, offset: 2 }} sm={{ span: 8, offset: 0 }}>
                            <InfoItem
                                containerClass={styles.auth}
                                value={
                                    <div className={styles.field}>
                                        <Form.Item className={styles.input} name="earnPoint">
                                            <Input />
                                        </Form.Item>
                                        <span>%</span>
                                    </div>
                                }
                                label={<span className={styles.label}>Author earning</span>}
                            />
                        </Col>
                        <Col xs={{ span: 12 }} sm={{ span: 8, offset: 0 }}>
                            <InfoItem
                                containerClass={styles.expired}
                                value={
                                    <div className={styles.field}>
                                        <Form.Item className={styles.input} name="expiredDay">
                                            <Input />
                                        </Form.Item>
                                        <span>days</span>
                                    </div>
                                }
                                label={<span className={styles.label}>Expired after </span>}
                            />
                        </Col>
                        <Col xs={{ span: 10, offset: 2 }} sm={{ span: 8, offset: 0 }}>
                            <InfoItem
                                containerClass={styles.amount}
                                value={
                                    <div className={styles.field}>
                                        <Form.Item className={styles.input} name="amount">
                                            <Input />
                                        </Form.Item>
                                    </div>
                                }
                                label={<span className={styles.label}>Minted amount</span>}
                            />
                        </Col>
                    </Row>
                    <MintInfo value={minFee} />
                    <Button className={styles.button} htmlType="submit" disabled={!isEvery} loading={loading}>
                        Mint
                    </Button>
                    <span className={styles.warning}>
                        License NFT will join the corresponding collection created by Ori automatically.
                    </span>
                </div>
            </Form>
        </>
    );
}
export default NFTMintModalContent;
