// export { default } from '@/components/userInfo/index';
import Icon from '@ant-design/icons';
import { memo, useMemo } from 'react';
import iconMap from './svg';

const MIcon = (props: any) => {
    const component = useMemo(() => {
        return (iconMap as any)[props.name] || <></>;
    }, [props.name]);

    const className = useMemo(() => {
        return [props.className || '', 'micon'].join(' ');
    }, [props.className]);

    return <Icon component={component} {...props} className={className} />;
};

export default memo(MIcon);
