import { Skeleton } from 'antd';
import styles from './style.module.less';

export interface InfoItemProps {
    label: string | React.ReactNode;
    value: string | React.ReactNode;
    containerClass?: string;
    loading?: boolean;
}

const InfoItem = ({ loading = false, label, value, containerClass }: InfoItemProps) => {
    return (
        <Skeleton loading={loading}>
            <div className={`${containerClass || ''} ${styles['info-container']} `}>
                {typeof value === 'string' ? <span className="primary-text">{value}</span> : value}
                {typeof label === 'string' ? <span className="secondary-text">{label}</span> : label}
            </div>
        </Skeleton>
    );
};

export default InfoItem;
