export const validate = (value: string | number, patten: RegExp) => {
    if (patten.test(String(value))) return true;
    return false;
};

const regFloatNum = (num: number) => {
    return new RegExp(`/^[0-9]+(.[0-9]{0,${num}})?$/`);
};

export const reg = {
    positiveNumber: /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/,
    positiveInteger: /^[0-9]*[1-9][0-9]*$/,
    positiveFloatTwo: /^[0-9]+(.[0-9]{0,2})?$/,
};
