import MIcon from '@/components/mIcon';
import { memo } from 'react';

const Contact = () => {
    return (
        <div className="contact-container">
            <div className="contact-content">
                <div className="item">
                    <a href="https://twitter.com/ori_protocol" target="_blank">
                        <MIcon name="icon05" />
                    </a>
                </div>
                {/* <div className="item">
                    <a href="https://twitter.com/ori_protocol" target="_blank">
                        <MIcon name="icon06" />
                    </a>
                </div> */}
                <div className="item">
                    <a href="https://discord.gg/UfjVh6Q8BC" target="_blank">
                        <MIcon name="icon10" />
                    </a>
                </div>
            </div>
        </div>
    );
};

export default memo(Contact);
