import { initializeApp } from 'firebase/app';

export function firebaseInit() {
    const firebaseConfig = {
        apiKey: 'AIzaSyCG6cqrhjy5zM0KDtynLT6ND61lMHtGdeI',
        authDomain: 'particle-network.firebaseapp.com',
        projectId: 'particle-network',
        storageBucket: 'particle-network.appspot.com',
        messagingSenderId: '977003236402',
        appId: '1:977003236402:web:8890a57de7e4989ff2002b',
        measurementId: 'G-0SY1PX476B',
    };
    initializeApp(firebaseConfig);
}
